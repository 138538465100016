<!-- Registration for new stream users -->

<template>
    <v-card class="pa-3" max-width="800">
        
        <h1>{{ $t("register.title") }}</h1>

        <!-- Form -->
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
        >
            
            <div class="page" v-if="page == 1">
                <p>
                    {{ $t("register.description") }}
                </p>

                <!-- Email -->
                <v-text-field
                    v-model="form.email"
                    :label="$t('register.email')"
                    required
                    type="email" 
                    :rules="rules.email"
                    autocomplete="off"
                    clearable
                ></v-text-field>

                <!-- Password -->
                <v-text-field
                    v-model="form.password"
                    :label="$t('register.password')"
                    required
                    :type="showPassword ? 'text' : 'password'" 
                    :rules="rules.password"
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (showPassword = !showPassword)"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="form.passwordConfirm"
                    :label="$t('register.passwordConfirm')"
                    required
                    :type="showPasswordConfirm ? 'text' : 'password'" 
                    :rules="rules.passwordConfirm"
                    autocomplete="off"
                    :append-icon="showPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (showPasswordConfirm = !showPasswordConfirm)"
                    clearable
                ></v-text-field>

                <v-btn color="primary" @click="nextPage">{{ $t("register.next") }}</v-btn>

            </div>
            <div class="page" v-if="page == 2">
                
                <!-- Name -->
                <div class="flexRow flexRowMobile">
                    <v-text-field
                        v-model="form.firstName"
                        :label="$t('register.firstName')"
                        :rules="rules.firstName"
                        required
                        autocomplete="off"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="form.lastName"
                        :label="$t('register.lastName')"
                        :rules="rules.lastName"
                        required
                        autocomplete="off"
                        clearable
                    ></v-text-field>
                </div>

                <!-- Account type -->
                <span>{{ $t("register.type") }}</span>

                <v-radio-group 
                    v-model="form.type" 
                    :rules="rules.type"
                >
                    <v-radio
                        v-for="type in typeOptions"
                        :key="type.value"
                        :label="type.text"
                        :value="type.value"
                    ></v-radio>
                </v-radio-group>

                <!-- Organisation -->
                <v-text-field
                    v-model="form.organisation"
                    :label="$t('register.organisation')"
                    v-if="form.type == 'organisation'"
                    required
                    autocomplete="off"
                    clearable
                ></v-text-field>

                <!-- Terms and conditions & Privacy -->
                <v-checkbox 
                    v-model="form.terms" 
                    :rules="rules.terms"
                    required
                >
                    <template v-slot:label>
                        <div>
                            {{ $t("register.confirm.label") }}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <a href="https://illi-tv.nl/algemene-voorwaarden/" target="_blank" v-bind="attrs" v-on="on" @click.stop>
                                        {{ $t("register.confirm.terms") }}
                                    </a>
                                </template>
                                {{ $t("register.confirm.newTab") }}
                            </v-tooltip>
                            {{ $t("register.confirm.and") }}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <a href="https://illi-tv.nl/privacy/" target="_blank" v-bind="attrs" v-on="on" @click.stop>
                                        {{ $t("register.confirm.privacy") }}
                                    </a>
                                </template>
                                {{ $t("register.confirm.newTab") }}
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
                
                <div class="flexRow">
                    <v-btn :disabled="!valid" color="primary" type="submit">{{ $t("register.submit") }}</v-btn>
                    <v-btn color="blue-grey lighten-5" @click="page--">{{ $t("register.previous") }}</v-btn>
                </div>


            </div>

        </v-form>
        
        <!-- Feedback -->
        <v-snackbar v-model="feedback.active" :timeout="7000" :color="feedback.color">
            <strong>{{ feedback.message }}</strong>
        </v-snackbar>

    </v-card>
</template>

<script>
import Vue from "vue";
import { ILLI_AUTH_SERVER_URL, ILLI_API_SERVER_URL } from "../config";
import axios from "axios";

export default Vue.extend({
    data() {
        return {
            valid: true,
            form: {
                email: null,
                password: null,
                passwordConfirm: null,
                firstName: null,
                lastName: null,
                type: null,
                organisation: null,
                terms: false
            },
            typeOptions: [
                { text: 'Mezelf', value: 'personal' },
                { text: 'Een familielid', value: 'family' },
                { text: 'Een organisatie', value: 'organisation' },
            ],
            showPassword: false,
            showPasswordConfirm: false,
            rules: {
                email: [
                    v => !!v || 'Email is verplicht.', 
                    v => /.+@.+\..+/.test(v) || 'Email moet geldig zijn.'
                ],
                password: [
                    v => !!v || 'Wachtwoord is verplicht.',
                    v => v?.length >= 8 || 'Wachtwoord moet minimaal 8 tekens lang zijn.'
                ],
                passwordConfirm: [v => v == this.form.password || 'Wachtwoord moet overeenkomen.'],
                firstName: [v => !!v || 'Voornaam is verplicht.'],
                lastName: [v => !!v || 'Achternaam is verplicht.'],
                type: [v => !!v || 'Account type is verplicht.'],
                terms: [v => !!v || 'U dient akkoord te gaan met de algemene voorwaarden en privacyverklaring.']
            },
            feedback: {
                active: false,
                message: '',
                color: ''
            },
            page: 1,
        };
    },
    methods: {

        nextPage() {
            this.valid = this.$refs.form.validate();
            if (this.valid) {
                this.page++;
            }
        },

        submit() {
            console.warn('SUBMIT');

            this.valid = this.$refs.form.validate();
            
            if (this.valid) {

                axios.post(`${ILLI_AUTH_SERVER_URL}/streamer`, {
                    name: 'Mr illi gebruiker',
                    email: this.form.email,
                    password: this.form.password,
                })
                .then((response) => {

                    const userId = response.data.id;

                    axios.post(`${ILLI_API_SERVER_URL}/stream/account/open`, {
                        user_id: userId,
                        firstName: this.form.firstName,
                        lastName: this.form.lastName,
                        type: this.form.type,
                        organisation: this.form.organisation,
                    })
                    .then((response) => {
                        console.log({response});
                        this.feedback.message = 'Account aangemaakt! Bevestig uw email om in te loggen.';
                        this.feedback.color = 'success';
                    })
                    .catch((error) => {
                        console.log({error});
                        this.feedback.message = 'Er ging iets fout. Probeer het later nog eens.';
                        this.feedback.color = 'red';
                    })
                    .finally(() => {
                        this.feedback.active = true;
                    });

                })
                .catch((error) => {
                    console.log({error});
                    this.feedback.message = 'Er ging iets fout. Probeer het later nog eens.';
                    this.feedback.color = 'red';
                    this.feedback.active = true;
                })


            } else {
                console.warn("Form is invalid.");
            }
        }


    }
})

</script>

<style scoped>

.flexRow{
    display: flex;
    gap: 15px;
}

@media screen and (max-width: 600px){
    .flexRowMobile{
        flex-direction: column;
        gap: 0;
    }
}

</style>